<template>
  <b-notification v-show="hasAuthenticationStatus" :type="authenticationStatus.variant">
    {{ authenticationStatus.message }}
  </b-notification>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", ["authenticationStatus", "hasAuthenticationStatus"]),
  },
  mounted() {
    // clear existing status message
    this.$store.dispatch("auth/clearAuthenticationStatus", null, {
      root: true,
    });
  },
};
</script>
