/* Styles */
import "@/scss/main.scss";
import "@mdi/font/css/materialdesignicons.css";

/* Core */
import Vue from "vue";
import Buefy from "buefy";

/* Router & Store */

/* Service Worker */
import "./registerServiceWorker";

/* Vue. Main component */

/* Vue. Component in recursion */

import { firebase } from "@firebase/app";
import Chartkick from "vue-chartkick";
import { Chart } from "chart.js";

import AudioVisual from "vue-audio-visual";

import VueClipboard from "vue-clipboard2";
import amplitude from "amplitude-js";
import LogRocket from "logrocket";
import Cookies from "js-cookie";
import AsideMenuList from "@/components/AsideMenuList";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import identifyUser from "./lib/identify-user";

const numeral = require("numeral");

Vue.filter(
  "formatNumber",
  value => numeral(value).format("0,0") // displaying other groupings/separators is possible, look at the docs
);

Vue.use(AudioVisual);
Vue.use(VueClipboard);
const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_authDomain,
  projectId: process.env.VUE_APP_PROJECTID,
  storageBucket: process.env.VUE_APP_storageBucket,
  messagingSenderId: process.env.VUE_APP_messagingSenderId,
};

firebase.initializeApp(firebaseConfig);

/* Collapse mobile aside menu on route change */
router.afterEach(() => {
  store.commit("asideMobileStateToggle", false);
});

Vue.config.productionTip = false;

/* These components are used in recursion algorithm */
Vue.component("AsideMenuList", AsideMenuList);
Vue.use(Chartkick.use(Chart));

Vue.use(Buefy);

const isMocked = Cookies.get("mockToken");
const isProduction = process.env.NODE_ENV === "production";

amplitude.getInstance().init("83b95919df1e9ca54bc7c6dc53d2cc37");

if (isProduction && !isMocked) {
  LogRocket.init("ahnitk/prod-pentos-dash-vue");
}

Vue.prototype.$amplitude = amplitude;

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

let app;
firebase.auth().onAuthStateChanged(user => {
  if (!app) {
    if (user) {
      identifyUser(user);
      store.dispatch("auth/userStateUpdate", user);
    } else {
      store.dispatch("auth/clearAuthentication", null, { root: true });
    }
    app = new Vue({
      router,
      store,
      components: { App },
      template: "<App/>",
      render: h => h(App),
    }).$mount("#app");
  }
});
