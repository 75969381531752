import firebase from "firebase";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";

// initial state
const state = {
  user: null,
  isAuthenticated: false,
  authenticationStatus: null,
  token: "",
};

const getters = {
  authenticatedUser: state => state.user,
  isAuthenticated: state => state.isAuthenticated,
  getToken: state => state.token,
  authenticationStatus: state =>
    state.authenticationStatus ? state.authenticationStatus : { variant: "" },
  hasAuthenticationStatus: state => !!state.authenticationStatus,
};

const mutations = {
  setAuthenticationError(state, err) {
    state.authenticationStatus = {
      state: "failed",
      message: err.message,
      variant: "is-danger",
    };
  },
  clearAuthenticationStatus: state => {
    state.authenticationStatus = null;
    state.token = "";
  },
  setUserAuthenticated(state, user) {
    state.user = user;
    if (user !== null) {
      localStorage.setItem("authenticated", true);
      state.isAuthenticated = true;
    } else {
      localStorage.setItem("authenticated", false);
      state.isAuthenticated = false;
    }
  },
  setToken(state, token) {
    state.token = token;
    localStorage.setItem("token", token);
  },
  clearAuthentication(state) {
    state.user = null;
    state.userId = null;
    state.isAuthenticated = false;
  },
};

const actions = {
  clearAuthenticationStatus: context => {
    context.commit("clearAuthenticationStatus", null);
  },
  signInWithToken: async (context, params) => {
    try {
      await firebase.auth().signInWithCustomToken(params.token);
      Cookies.set("mockToken", params.token);
      const user = firebase.auth().currentUser;

      await context.commit("setUserAuthenticated", user);
      context.dispatch("user/getUser", null, { root: true });
      context.dispatch("tiktok/getTiktok", null, { root: true });
    } catch (err) {
      context.commit("auth/setAuthenticationError", err, { root: true });
    }
  },
  signIn: async (context, params) => {
    context.commit("auth/clearAuthenticationStatus", null, { root: true });
    try {
      await firebase.auth().signInWithEmailAndPassword(params.username, params.password);
      const user = firebase.auth().currentUser;

      await context.commit("setUserAuthenticated", user);
      context.dispatch("user/getUser", null, { root: true });
      context.dispatch("tiktok/getTiktok", null, { root: true });

      return user;
    } catch (err) {
      context.commit("auth/setAuthenticationError", err, { root: true });
    }
  },
  signOut: async context => {
    Cookies.remove("mockToken");
    context.commit("auth/clearAuthentication", null, { root: true });
    try {
      await firebase.auth().signOut();
    } catch (err) {
      //   logger.error('error during sign out: {}', err)
    }
  },
  signInWithGoogle: (context, params) =>
    new Promise((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(result => {
          const docRef = firebase
            .firestore()
            .collection("users")
            .doc(result.user.uid);
          docRef
            .get()
            .then(doc => {
              if (doc.exists) {
                const user = firebase.auth().currentUser;
                context.commit("setUserAuthenticated", user);
                context.dispatch("user/getUser", null, { root: true });
                context.dispatch("tiktok/getTiktok", null, { root: true });
                resolve(result.user);
              } else {
                const refCode = result.user.uid.split("-")[0];
                firebase
                  .firestore()
                  .collection("users")
                  .doc(result.user.uid)
                  .set({
                    email: result.user.email,
                    plan: "free",
                    name: result.user.displayName,
                    creation_date: firebase.firestore.Timestamp.fromDate(new Date()),
                    limit: 0,
                    referrer_code: "",
                    referral_code: refCode,
                    weekly_report: true,
                    alerts_limit: 0,
                  })
                  .then(() => {
                    const user = firebase.auth().currentUser;
                    context.commit("setUserAuthenticated", user);
                    context.dispatch("user/getUser", null, { root: true });
                    context.dispatch("tiktok/getTiktok", null, { root: true });

                    firebase
                      .firestore()
                      .collection("tiktok")
                      .doc(user.uid)
                      .set({ limit: 1 });
                    resolve(user); // Let the calling function know that http is done. You may send some data back
                  })
                  .catch(err => {
                    context.commit("auth/setAuthenticationError", err, {
                      root: true,
                    });
                    reject(err);
                  });
              }
            })
            .catch(error => {
              console.log("Error getting document:", error);
            });
        })
        .catch(err => {
          context.commit("auth/setAuthenticationError", err, { root: true });
          reject(err);
        });
    }),
  signUpWithGoogle: (context, params) =>
    new Promise((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(result => {
          const refCode = result.user.uid.split("-")[0];
          firebase
            .firestore()
            .collection("users")
            .doc(result.user.uid)
            .set({
              email: result.user.email,
              plan: "free",
              name: result.user.displayName,
              creation_date: firebase.firestore.Timestamp.fromDate(new Date()),
              limit: 0,
              referrer_code: "",
              referral_code: refCode,
              weekly_report: true,
              alerts_limit: 0,
            })
            .then(() => {
              const user = firebase.auth().currentUser;
              context.commit("setUserAuthenticated", user);
              context.dispatch("user/getUser", null, { root: true });
              context.dispatch("tiktok/getTiktok", null, { root: true });

              firebase
                .firestore()
                .collection("tiktok")
                .doc(user.uid)
                .set({ limit: 1 });
              resolve(user); // Let the calling function know that http is done. You may send some data back
            })
            .catch(err => {
              context.commit("auth/setAuthenticationError", err, {
                root: true,
              });
              reject(err);
            });
        })
        .catch(err => {
          context.commit("auth/setAuthenticationError", err, { root: true });
          reject(err);
        });
    }),
  signUp: async (context, params) => {
    context.commit("auth/clearAuthenticationStatus", null, { root: true });
    try {
      await firebase.auth().createUserWithEmailAndPassword(params.email, params.password);
      const uuid = uuidv4();
      const user = firebase.auth().currentUser;
      const refCode = uuid.split("-")[0];
      await firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .set({
          email: params.email,
          plan: "free",
          name: params.name,
          creation_date: firebase.firestore.Timestamp.fromDate(new Date()),
          limit: 0,
          referrer_code: params.referral_code,
          referral_code: refCode,
          weekly_report: true,
          alerts_limit: 0,
        });
      await firebase
        .firestore()
        .collection("tiktok")
        .doc(user.uid)
        .set({ limit: 1 });
      if (params.referral_code !== "") {
        axios
          .post(`${process.env.VUE_APP_REFERRAL_SERVICE}/referral`, {
            referral_code: params.referral_code,
            email: params.email,
          })
          .catch(e => e);
      }
      await context.commit("setUserAuthenticated", user);
      context.dispatch("user/getUser", null, { root: true });
      context.dispatch("tiktok/getTiktok", null, { root: true });
      return user;
    } catch (err) {
      context.commit("auth/setAuthenticationError", err, { root: true });
    }
  },

  passwordReset: async (context, params) => {
    context.commit("auth/clearAuthenticationStatus", null, { root: true });
    try {
      await firebase.auth().sendPasswordResetEmail(params.username);
    } catch (err) {
      context.commit("auth/setAuthenticationError", err, { root: true });
    }
  },
  confirmPasswordReset: async (context, params) => {
    context.commit("auth/clearAuthenticationStatus", null, { root: true });
    try {
      await firebase.auth().confirmPasswordReset(params.code, params.password);
    } catch (err) {
      context.commit("auth/setAuthenticationError", err, { root: true });
    }
  },

  passwordChange: async (context, params) => {
    // logger.debug('password change for {}', context.state.user.email)
    context.commit("auth/clearAuthenticationStatus", null, { root: true });
    try {
      const user = firebase.auth().currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(user.email, params.password);
      await user.reauthenticateAndRetrieveDataWithCredential(credential);
      await user.updatePassword(params.newPassword);
    } catch (err) {
      context.commit("auth/setAuthenticationError", err, { root: true });
    }
  },
  userStateUpdate: (context, user) => {
    context.commit("setUserAuthenticated", user);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
