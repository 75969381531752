import firebase from "firebase";
import amplitude from "amplitude-js";

const state = {
  currentUser: null,
};

const getters = {
  user: state => state.currentUser,
};

const mutations = {
  setUser(state, user) {
    state.currentUser = user;
  },
  clearUser(state) {
    state.currentUser = null;
  },
};

const actions = {
  clearUser: context => {
    context.commit("clearAuthenticationStatus", null);
  },
  updateUser: () => {},
  getUser: context => {
    const user = firebase.auth().currentUser;
    const docRef = firebase
      .firestore()
      .collection("users")
      .doc(user.uid);
    docRef
      .get()
      .then(doc => {
        if (doc.exists) {
          context.commit("setUser", doc.data());
          const identify = new amplitude.Identify().set("plan", doc.data().plan);
          amplitude.getInstance().identify(identify);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(error => {
        console.log("Error getting document:", error);
      });
  },
  updateUsersetting: (context, params) => {
    const user = firebase.auth().currentUser;
    firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .update({
        weekly_report: params.weekly_report,
      });
  },
  cancelSubscription: (context, params) => {
    const user = firebase.auth().currentUser;
    firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .update({
        has_cancelled: true,
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
