import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import Home from "./views/Home.vue";
import Result from "./views/Result.vue";
import NotFound from "@/pages/NotFound.vue";

import SignIn from "@/pages/auth/SignIn.vue";
import SignUp from "@/pages/auth/SignUp.vue";
import SignUpWaitlist from "@/pages/auth/SignUpWaitlist.vue";
// // import SignOut from '@/pages/auth/SignOut.vue'
import PasswordReset from "@/pages/auth/PasswordReset.vue";
// // import ChangePassword from '@/pages/auth/ChangePassword.vue'
import ConfirmPasswordReset from "@/pages/auth/ConfirmPasswordReset.vue";
import Cookies from "js-cookie";

Vue.use(Router);

const isMocked = Cookies.get("mockToken");

function mockFavicon() {
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = "/favicon-mock.ico";
}

if (isMocked) {
  mockFavicon();
}

const routes = [
  {
    path: "/",
    name: "sign in",
    component: SignIn,
    meta: { title: "SignIn", auth: false },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Home,
    meta: { title: "dashboard", auth: true },
  },
  {
    path: "/analytics",
    name: "Analytics",
    component: Result,
    meta: { title: "Analytics", auth: true },
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "user/:id",
        component: () => import(/* webpackChunkName: "forms" */ "./components/user/UserResult.vue"),
        meta: { title: "User Result", auth: true },
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "hashtag/:id",
        component: () =>
          import(/* webpackChunkName: "forms" */ "./components/hashtag/HashtagResult.vue"),
        meta: { title: "Hashtag Result", auth: true },
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "song/:id",
        component: () =>
          import(/* webpackChunkName: "forms" */ "./components/music/MusicAnalytics.vue"),
        meta: { title: "Music Result", auth: true },
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "post/:id",
        component: () =>
          import(/* webpackChunkName: "forms" */ "./components/post/PostAnalytics.vue"),
        meta: { title: "Post Result", auth: true },
      },
    ],
  },
  {
    path: "/signin",
    name: "signIn",
    component: SignIn,
  },
  {
    path: "/signup",
    name: "signUp",
    component: SignUpWaitlist,
  },
  {
    path: "/tables",
    name: "tables",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tables" */ "./views/Tables.vue"),
  },
  {
    path: "/new",
    name: "new",
    component: () => import(/* webpackChunkName: "forms" */ "./views/New.vue"),
    meta: { title: "New", auth: true },
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "user",
        component: () => import(/* webpackChunkName: "forms" */ "./components/user/UserSearch.vue"),
        meta: { title: "New User", auth: true },
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "hashtag",
        component: () =>
          import(/* webpackChunkName: "forms" */ "./components/hashtag/HashtagSearch.vue"),
        meta: { title: "New Hashtag", auth: true },
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "music",
        component: () =>
          import(/* webpackChunkName: "forms" */ "./components/music/MusicSearch.vue"),
        meta: { title: "New Music", auth: true },
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "post",
        component: () => import(/* webpackChunkName: "forms" */ "./components/post/PostSearch.vue"),
        meta: { title: "New Post", auth: true },
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        path: "youtube",
        component: () =>
          import(/* webpackChunkName: "forms" */ "./components/youtube/YoutubeSearch.vue"),
        meta: { title: "Track a YouTube channel", auth: true },
      },
    ],
  },
  {
    path: "/setting",
    name: "Setting",
    component: () => import(/* webpackChunkName: "setting" */ "./views/Setting.vue"),
    meta: { title: "Setting", auth: true },
  },
  {
    path: "/trending",
    name: "Trending",
    component: () => import(/* webpackChunkName: "trending" */ "./views/Trending.vue"),
    meta: { title: "Trending", auth: true },
  },
  {
    path: "/charts",
    name: "Charts",
    component: () => import(/* webpackChunkName: "charts" */ "./views/Charts.vue"),

    meta: { title: "Charts", auth: true },
  },
  {
    path: "/challenge",
    name: "Challenge",
    component: () => import(/* webpackChunkName: "charts" */ "./views/HashtagChart.vue"),

    meta: { title: "Challenge rank", auth: true },
  },
  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    path: "/challenge/:id",
    name: "challenge",
    component: () =>
      import(/* webpackChunkName: "charts" */ "./components/hashtagcharts/Challenge.vue"),
    meta: { title: "Hashtag Result", auth: true },
  },
  {
    path: "/home",
    name: "dashboard",
    component: () => import(/* webpackChunkName: "trending" */ "./views/Dashboard.vue"),
    meta: { title: "Dashboard", auth: true },
  },
  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    path: "/charts/:id",
    name: "chart",
    component: () => import(/* webpackChunkName: "charts" */ "./components/viral/Track.vue"),
    meta: { title: "Chart Result", auth: true },
  },
  {
    path: "/artist/:id",
    name: "artist",
    component: () => import(/* webpackChunkName: "artists" */ "./views/Artists.vue"),
    meta: { title: "Artist", auth: true },
  },
  {
    path: "/search",
    name: "search",
    component: () => import(/* webpackChunkName: "artists" */ "./views/Search.vue"),
    meta: { title: "Search", auth: true },
  },
  {
    path: "/users",
    name: "users",
    component: () => import(/* webpackChunkName: "users" */ "./views/Users.vue"),
    meta: { title: "Users", auth: true },
  },
  {
    path: "/hashtags",
    name: "Hashtags",
    component: () => import(/* webpackChunkName: "hashtags" */ "./views/Hashtags.vue"),
    meta: { title: "Hashtags", auth: true },
  },
  {
    path: "/songs",
    name: "Songs",
    component: () => import(/* webpackChunkName: "songs" */ "./views/Songs.vue"),
    meta: { title: "Songs", auth: true },
  },
  {
    path: "/posts",
    name: "Posts",
    component: () => import(/* webpackChunkName: "trending" */ "./views/Posts.vue"),
    meta: { title: "Posts", auth: true },
  },
  {
    path: "/youtube",
    name: "Youtube",
    component: () => import(/* webpackChunkName: "trending" */ "./views/Youtube.vue"),
    meta: { title: "Youtube", auth: true },
  },
  {
    path: "/thankyou",
    name: "Thank You",
    component: () => import(/* webpackChunkName: "profile" */ "./views/ThankYou.vue"),
    meta: { title: "Thank you", auth: true },
  },
  {
    path: "/passwordReset",
    name: "passwordReset",
    component: PasswordReset,
    meta: { title: "Password Reset", auth: false },
  },
  {
    path: "/confirmPasswordReset",
    name: "confirmPasswordReset",
    component: ConfirmPasswordReset,
    meta: { title: "Confirm Password Reset", auth: false },
  },
  { path: "*", component: NotFound },
];

const scrollBehavior = function(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  }
  return { x: 0, y: 0 };
};
const router = new Router({
  mode: "history",
  scrollBehavior,
  routes,
});

router.beforeEach((to, from, next) => {
  // Use the page's router title to name the page
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }

  // is there a meta and auth attribute?
  if (to.meta && to.meta.auth !== undefined) {
    // if the page requires auth
    if (to.meta.auth) {
      // and we are authenticated?
      if (store.getters["auth/isAuthenticated"]) {
        next(); // route normally
        return;
      }
      // otherwise off to the sign in page
      router.push({ name: "signIn" });
      return;
    }
    // otherwise are we already authenticated?
    if (store.getters["auth/isAuthenticated"]) {
      // yes we are, so off to dashboard
      router.push({ name: "dashboard" });
      return;
    }
    next(); // route normally
    return;
  }
  next(); // route normally
});

export default router;
