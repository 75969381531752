<template>
  <div id="app">
    <div v-if="isAuthenticated">
      <nav-bar />
      <aside-menu :menu="menu" />
      <router-view />

      <!-- <footer-bar/> -->
    </div>
    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<style lang="scss"></style>
<script>
import { mapGetters } from "vuex";
import NavBar from "@/components/NavBar";
import AsideMenu from "@/components/AsideMenu";

export default {
  components: { AsideMenu, NavBar },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    menu() {
      return [
        {
          to: "/users",
          icon: "account",
          label: "Users",
        },
        {
          to: "/hashtags",
          icon: "pound",
          label: "Hashtags",
        },
        {
          to: "/songs",
          icon: "music",
          label: "Songs",
        },
        {
          to: "/posts",
          icon: "video",
          label: "Videos",
        },
        {
          to: "/search",
          icon: "magnify",
          label: "Search for Artists or Songs",
        },
        {
          to: "/charts",
          icon: "fire",
          label: "Sound Charts",
        },
        {
          to: "/challenge",
          icon: "finance",
          label: "Challenge leaderboard",
        },
        {
          to: "/trending",
          icon: "trending-up",
          label: "Trending",
        },
        {
          to: "/youtube",
          icon: "youtube",
          label: "Youtube",
        },
        {
          to: "/setting",
          icon: "cog-outline",
          label: "Settings",
        },
      ];
    },
  },
  created() {},
};
</script>
