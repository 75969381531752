<template>
  <section class="is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="column is-4 is-offset-4">
          <h3 class="title has-text-black-bis">
            Sign Up
          </h3>
          <p class="subtitle has-text-grey">
            Sign up to use Pentos.
          </p>
          <div class="box">
            <!-- <figure class="avatar">
              <img src="../../assets/logo.png">
            </figure>-->
            <v-alert />
            <b-button
              class="button is-medium is-fullwidth has-margin-bottom-25 is-google"
              icon-left="google"
              @click="socialLogin"
            >
              Continue with Google
            </b-button>
            <div class="is-divider" data-content="OR" />

            <form @submit.prevent="signUp">
              <b-field>
                <b-input
                  v-model="name"
                  placeholder="Your name"
                  autofocus
                  required
                  size="is-large"
                  icon="account"
                />
              </b-field>
              <b-field>
                <b-input
                  v-model="email"
                  placeholder="Your Email"
                  autofocus
                  required
                  size="is-large"
                  type="email"
                  icon="at"
                />
              </b-field>
              <b-field
                :type="{ 'is-danger': hasError }"
                :message="[
                  { 'Password must have at least 8 characters': isTooSmall },
                  {
                    'Password should contain upper case lower case and digits': isComplex,
                  },
                ]"
              >
                <b-input
                  v-model="pass"
                  placeholder="Your password"
                  autofocus
                  size="is-large"
                  type="password"
                  icon="key"
                />
              </b-field>
              <b-field v-show="hasReferralCode === 'Yes'">
                <b-input
                  v-model="referral_code"
                  placeholder="Your referral code"
                  autofocus
                  size="is-large"
                  icon="account-arrow-left"
                />
              </b-field>
              <button
                v-if="!isLoading"
                class="button is-block is-primary is-large is-fullwidth"
                type="submit"
              >
                Submit
              </button>
              <button v-if="isLoading" class="button is-large is-primary is-fullwidth is-loading" />
            </form>
          </div>
          <p class="has-text-grey">
            <a href="/signin">Sign In</a> &nbsp;·&nbsp;
            <a href="/passwordReset">Forgot Password</a> &nbsp;·&nbsp;
            <!-- <a href="../">Need Help?</a> -->
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import router from "@/router";
import Alert from "@/components/auth/Alert.vue";
import identifyUser from "../../lib/identify-user";

Vue.component("v-alert", Alert);

const PasswordValidator = require("password-validator");

const schema = new PasswordValidator();
schema
  .is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits();

export default {
  data() {
    return {
      email: "",
      name: "",
      pass: "",
      referral_code: "",
      isLoading: false,
      hasReferralCode: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    isTooSmall() {
      return this.pass.length < 8 && this.pass.length !== 0;
    },
    isComplex() {
      return !schema.validate(this.pass) && this.pass.length !== 0;
    },
    hasError() {
      return this.isTooSmall || this.isComplex;
    },
  },
  mounted() {
    // clear existing status message
    this.$amplitude.logEvent("user start signup");
    this.$store.dispatch("auth/clearAuthenticationStatus", null, {
      root: true,
    });
    if (this.$route.query.email !== null) {
      this.email = this.$route.query.email;
    }
    if (this.$route.query.referral_code !== undefined) {
      this.referral_code = this.$route.query.referral_code;
      this.hasReferralCode = "Yes";
    } else {
      this.hasReferralCode = "No";
    }
  },

  methods: {
    async signUp() {
      this.isLoading = true;
      const user = await this.$store.dispatch("auth/signUp", {
        email: this.email,
        password: this.pass,
        name: this.name,
        referral_code: this.referral_code === undefined ? "" : this.referral_code,
      });
      if (this.isAuthenticated) {
        identifyUser(user);
        this.$amplitude.logEvent("user has signup");
        router.push("dashboard");
      }
      this.isLoading = false;
    },
    socialLogin() {
      this.$store.dispatch("auth/signUpWithGoogle").then(user => {
        identifyUser(user);
        this.$amplitude.logEvent("user has signup");
        router.push("dashboard");
      });
    },
  },
};
</script>
<style></style>
