import Vue from "vue";
import Vuex from "vuex";
import createPersist from "vuex-localstorage";

import auth from "./modules/auth";
import user from "./modules/user";
import tiktok from "./modules/tiktok";
import trending from "./modules/trending";
import notifications from "./modules/notifications";
import chart from "./modules/chart";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    /* NavBar */
    isNavBarVisible: true,

    /* FooterBar */
    isFooterBarVisible: true,

    /* Aside */
    isAsideVisible: true,
    isAsideMobileExpanded: false,
  },
  mutations: {
    /* A fit-them-all commit */
    basic(state, payload) {
      state[payload.key] = payload.value;
    },
    asideMobileStateToggle(state, payload = null) {
      const htmlClassName = "has-aside-mobile-expanded";

      let isShow;

      if (payload !== null) {
        isShow = payload;
      } else {
        isShow = !state.isAsideMobileExpanded;
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName);
      } else {
        document.documentElement.classList.remove(htmlClassName);
      }

      state.isAsideMobileExpanded = isShow;
    },
  },

  modules: {
    auth,
    user,
    tiktok,
    trending,
    notifications,
    chart,
  },
  strict: false,
  plugins: [
    createPersist({
      namespace: "bartoc",
      initialState: {},
      // ONE_WEEK
      expires: 7 * 24 * 60 * 60 * 1e3,
    }),
  ],
});

export default store;
