import axios from "axios";

const state = {
  sound: {},
  videos: {},
  artist: {},
  challenge: {},
  loading: true,
};

const getters = {
  sound: state => {
    if (state.sound === null) {
      return {};
    }

    return state.sound;
  },
  challenge: state => {
    if (state.challenge === null) {
      return {};
    }

    return state.challenge;
  },
  artist: state => {
    if (state.artist === null) {
      return {};
    }

    return state.artist;
  },

  videos: state => {
    if (state.videos === null) {
      return {};
    }

    return state.videos;
  },
  loading: state => state.loading,
};

const mutations = {
  setSound(state, sound) {
    state.sound = sound;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setVideos(state, videos) {
    state.videos = videos;
  },
  setArtist(state, artist) {
    state.artist = artist;
  },
  setChallenge(state, challenge) {
    state.challenge = challenge;
  },
};

const actions = {
  getSound: async (context, params) => {
    context.commit("setLoading", true);
    context.commit("setSound", {});
    context.commit("setVideos", {});

    const data = await axios.get(`${process.env.VUE_APP_CHART_SERVICE}/api/song/${params.id}`);
    const videos = await axios.get(
      `https://backupservice-q3d6cebooq-ew.a.run.app/songchart/${params.id}`
    );
    context.commit("setSound", data.data);
    context.commit("setVideos", videos.data);
    context.commit("setLoading", false);

    // .catch((error) => {
    // });
  },

  getChallenge: async (context, params) => {
    context.commit("setLoading", true);
    context.commit("setChallenge", {});

    const data = await axios.get(`${process.env.VUE_APP_HASHTAG_SERVICE}/hashtags/${params.id}`);
    const stats = await axios.get(`${process.env.VUE_APP_HASHTAG_SERVICE}/stats/${params.id}`);
    const info = data.data;
    const analytics = stats.data;
    console.log(analytics);
    const result = { info: info, analytics: analytics };
    context.commit("setChallenge", result);
    context.commit("setLoading", false);

    // .catch((error) => {
    // });
  },
  getArtist: (context, params) => {
    context.commit("setLoading", true);
    axios
      .get(`${process.env.VUE_APP_CHART_SERVICE}/api/author/${params.id}`)
      .then(({ data }) => {
        // api.themoviedb.org manage max 1000 pages
        context.commit("setLoading", false);
        context.commit("setArtist", data);
        console.log(data);
      })
      .catch(() => {
        context.commit("setLoading", false);
        context.commit("setArtist", {});
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
