<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("tiktok", ["tiktokUser"]),
  },
  mounted() {
    this.$store.dispatch("tiktok/getTracking", { id: this.$route.params.id });
  },
};
</script>

<style></style>
