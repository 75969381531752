<template>
  <aside v-show="isAsideVisible" class="aside is-placed-left is-expanded">
    <router-link to="/dashboard">
      <figure class="image">
        <img class="is-rounded" src="../assets/p.jpg" />
      </figure>
    </router-link>
    <div class="menu is-menu-main">
      <aside-menu-list :menu="menu" @menu-click="menuClick" />
    </div>
  </aside>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AsideMenu",
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState(["isAsideVisible"]),
  },
  methods: {
    menuClick(item) {
      //
    },
  },
};
</script>
