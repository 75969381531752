import firebase from "firebase";

const state = {
  notifications: [],
};

const getters = {
  notifications: state => {
    if (state.notifications === null) {
      return 0;
    }
    const sortedNotifcations = state.notifications
      .slice()
      .sort((a, b) => b.notification_date - a.notification_date);
    return sortedNotifcations;
  },
  unreadNotification: state => {
    if (state.notifications === null) {
      return 0;
    }
    const unread = state.notifications.slice().filter(notif => notif.status === "unread");
    return unread.length;
  },
};

const mutations = {
  setNotifications(state, notifications) {
    state.notifications = notifications;
  },
};

const actions = {
  getNotifications: context => {
    const user = firebase.auth().currentUser;
    // eslint-disable-next-line no-debugger
    firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("notifications")
      .get()
      .then(function(querySnapshot) {
        const notifications = [];
        querySnapshot.forEach(function(doc) {
          const notif = doc.data();
          notif.id = doc.id;
          notifications.push(notif);
          // doc.data() is never undefined for query doc snapshots
        });
        context.commit("setNotifications", notifications);
      })
      .catch(error => {
        console.log("Error getting document:", error);
      });
  },
  markNotificationRead: (context, params) => {
    const user = firebase.auth().currentUser;
    const ref = firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("notifications")
      .doc(params.id);
    return ref
      .update({
        status: "read",
      })
      .then(() => {
        context.dispatch("getNotifications");
      })
      .catch(error => {
        console.log(error);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
