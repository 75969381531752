<template>
  <div>
    <div class="title-wrapper has-text-centered">
      <h3 class="subtitle is-4 has-text-grey">
        Frequently Asked Questions
      </h3>
      <div class="divider is-centered" />
    </div>
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <b-collapse aria-id="question0" :open="false">
          <p slot="trigger" slot-scope="props" role="button" aria-controls="question0">
            What is a tracker ?
            <b-icon type="is-info" :icon="props.open ? 'menu-down' : 'menu-up'" />
          </p>
          <article class="message is-primary">
            <div class="message-body">
              <div class="message-body-content">
                It represents the capacity to monitor a user, a hashtag or a song. The number of
                trackers you can have at the same time depends on the plan you subscribed to.
              </div>
            </div>
          </article>
        </b-collapse>
        <b-collapse aria-id="question1" :open="false">
          <p slot="trigger" slot-scope="props" role="button" aria-controls="question1">
            Can I upgrade or downgrade / switch plans at any time?
            <b-icon type="is-info" :icon="props.open ? 'menu-down' : 'menu-up'" />
          </p>
          <article class="message is-primary">
            <div class="message-body">
              <div class="message-body-content">
                Yes, Pentos is a pay-as-you-go service and you can upgrade, downgrade, cancel at
                anytime. Click on "Settings", to change your plan
              </div>
            </div>
          </article>
        </b-collapse>
        <b-collapse aria-id="question2" :open="false">
          <p slot="trigger" slot-scope="props" role="button" aria-controls="question2">
            What payment methods do you accept?
            <b-icon type="is-info" :icon="props.open ? 'menu-down' : 'menu-up'" />
          </p>
          <article class="message is-primary">
            <div class="message-body">
              <div class="message-body-content">
                We accept Visa, Mastercard, and American Express. All of our payements are handled
                by Stripe and follow EUR pricing.
              </div>
            </div>
          </article>
        </b-collapse>
        <b-collapse aria-id="question3" :open="false">
          <p slot="trigger" slot-scope="props" role="button" aria-controls="question3">
            Can I cancel my account at any time?
            <b-icon type="is-info" :icon="props.open ? 'menu-down' : 'menu-up'" />
          </p>
          <article class="message is-primary">
            <div class="message-body">
              <div class="message-body-content">
                Yes, you can change or cancel your plan at any time in your dashboard. Once you have
                canceled, you'll still have access to your premium plan until the end of your
                subscription cycle.
              </div>
            </div>
          </article>
        </b-collapse>
        <b-collapse aria-id="question4" :open="false">
          <p slot="trigger" slot-scope="props" role="button" aria-controls="question4">
            More questions ?
            <b-icon type="is-info" :icon="props.open ? 'menu-down' : 'menu-up'" />
          </p>
          <article class="message is-primary">
            <div class="message-body">
              <div class="message-body-content">
                Just send us an email at
                <a href="mailto:hello@pentos.co" target="_blank">hello@pentos.co</a>
                or chat with us !
              </div>
            </div>
          </article>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
