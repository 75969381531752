<template>
  <div>
    <div class="columns is-centered">
      <div class="column is-half has-text-centered">
        <b-icon icon="emoticon-sad-outline" size="is-large" />
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-half has-text-centered">
        You don't track any hashtags!
      </div>
    </div>
    <div class="columns container is-centered">
      <div class="column is-2 has-text-centered">
        <b-button
          v-if="canAddSearch"
          size="is-medium is-primary"
          tag="router-link"
          to="/new/hashtag"
          expanded
        >
          Track a hashtag
        </b-button>
        <div v-else>
          <b-button size="is-medium is-primary" disabled expanded>
            Track a hashtag
          </b-button>
          <div class="has-text-danger has-text-weight-light is-size-7">
            <router-link to="setting"> Upgrade </router-link>&nbsp;your account to track more
            hashtags.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("user", ["user"]),
    ...mapGetters("tiktok", ["tiktok"]),

    canAddSearch() {
      if (this.user.plan === "pro" && this.tiktok.length < this.user.limit) {
        return true;
      }
      if (this.user.plan === "growth" && this.tiktok.length < this.user.limit) {
        return true;
      }
      if (this.user.plan === "starter" && this.tiktok.length < this.user.limit) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style></style>
