<template>
  <article class="media">
    <div class="media-content">
      <div class="content">
        <p>
          <strong>{{ notif.title }}</strong>
          <small>{{ getLastModification }} ago</small>
          <br />
          {{ notif.message }}
        </p>
      </div>
      <nav class="level is-mobile">
        <div class="level-left" />
        <div class="level-right">
          <span v-if="notif.status === 'unread'" class="level-item">
            <b-button type="is-text" size="is-small" @click="markAsRead()">Mark as read</b-button>
          </span>
        </div>
      </nav>
    </div>
  </article>
</template>

<script>
const prettyMilliseconds = require("pretty-ms");

export default {
  props: {
    notif: Object,
  },
  computed: {
    getLastModification() {
      const startDate = new Date();
      const last = new Date(this.notif.notification_date.seconds * 1000);
      return prettyMilliseconds(startDate - last);
    },
  },
  mounted() {
    this.$store.dispatch("notifications/getNotifications");
  },
  methods: {
    markAsRead() {
      this.$amplitude.logEvent("User read notificati");

      this.$store.dispatch("notifications/markNotificationRead", {
        id: this.notif.id,
      });
    },
  },
};
</script>

<style></style>
