import firebase from "firebase";

const state = {
  trending: null,
};

const getters = {
  trending: state => state.trending,
};

const mutations = {
  setTrending(state, trending) {
    state.trending = trending;
  },
};
const actions = {
  getTrending: (context, params) => {
    const endingDate = new Date(params.date);
    endingDate.setDate(params.date.getDate() + 1);
    const trending = firebase
      .firestore()
      .collection("trending")
      .doc(params.location.toLowerCase())
      .collection("scan")
      .where("scan_date", ">=", params.date)
      .where("scan_date", "<=", endingDate);

    trending.get().then(querySnapshot => {
      querySnapshot.forEach(async doc => {
        const videos = [];
        await doc.ref
          .collection("video")
          .get()
          .then(d => {
            d.forEach(element => {
              videos.push(element.data());
            });
          });
        const hashtags = [];

        await doc.ref
          .collection("hashtag")
          .get()
          .then(d => {
            d.forEach(element => {
              hashtags.push(element.data());
            });
          });
        const songs = [];
        await doc.ref
          .collection("songs")
          .get()
          .then(d => {
            d.forEach(element => {
              songs.push(element.data());
            });
          });
        const users = [];
        await doc.ref
          .collection("users")
          .get()
          .then(d => {
            d.forEach(element => {
              users.push(element.data());
            });
          });
        context.commit("setTrending", {
          videos,
          hashtags,
          users,
          songs,
        });
      });
    });
  },
  clearTrending: context => {
    context.commit("setTrending", null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
