import amplitudeJs from "amplitude-js";
import LogRocket from "logrocket";
import Cookies from "js-cookie";

function identifyUser(user) {
  const isProduction = process.env.NODE_ENV === "production";
  const isMocked = Cookies.get("mockToken");

  function amplitude() {
    const identify = new amplitudeJs.Identify().set("email", user.email);
    amplitudeJs.getInstance().identify(identify);
  }

  function profitwell() {
    if (!isProduction || isMocked) {
      return;
    }

    window.profitwell("user_email", user.email);
  }

  function logrocket() {
    if (!isProduction || isMocked) {
      return;
    }

    LogRocket.identify(user.uid, {
      email: user.email,
    });
  }

  function heap() {
    window.heap.identify(user.uid);
    window.heap.addUserProperties({ email: user.email });
  }

  function setUserCookie() {
    Cookies.set("pentos_user", JSON.stringify({ uid: user.uid }));
  }

  setUserCookie();
  amplitude();
  profitwell();
  logrocket();
  heap();
}

export default identifyUser;
