<template>
  <div>
    <h1 class="title">
      Bonjour
    </h1>
    <h2 class="subtitle">
      Here's an aggregation of all your trackers by type
    </h2>

    <UserOverview />
    <HashtagOverview />
    <SongOverview />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserOverview from "@/components/home/UserOverview";
import HashtagOverview from "@/components/home/HashtagOverview";
import SongOverview from "@/components/home/SongOverview";

export default {
  components: { UserOverview, HashtagOverview, SongOverview },
  computed: {
    ...mapGetters("user", ["user"]),
    ...mapGetters("tiktok", ["tiktok"]),
    canAddSearch() {
      if (this.user.plan === "pro" && this.tiktok.length < this.user.limit) {
        return true;
      }
      if (this.user.plan === "growth" && this.tiktok.length < this.user.limit) {
        return true;
      }
      if (this.user.plan === "starter" && this.tiktok.length < this.user.limit) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.$store.dispatch("tiktok/getTiktok");
    this.$amplitude.logEvent("user is on home");
  },
};
</script>

<style></style>
