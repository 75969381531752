<template>
  <section class="is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="column is-4 is-offset-4">
          <h3 class="title has-text-grey">
            Reset Password
          </h3>
          <p class="subtitle has-text-grey">
            Request a password reset code via email.
          </p>
          <div class="box">
            <v-alert />

            <b-field label="Email:" label-for="usernameInput">
              <b-input
                id="usernameInput"
                v-model="username"
                type="email"
                required
                autofocus
                placeholder="Enter email"
                size="is-large"
                icon="at"
              />
            </b-field>
            <div class="is-2">
              <button class="button is-primary is-large" @click="passwordReset">
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import router from "@/router";
import store from "@/store";
import Alert from "@/components/auth/Alert.vue";

Vue.component("v-alert", Alert);
export default {
  data() {
    return {
      username: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["hasAuthenticationStatus"]),
  },
  methods: {
    async passwordReset() {
      await store.dispatch("auth/passwordReset", {
        username: this.username,
      });
      if (!this.hasAuthenticationStatus) {
        router.push("confirmPasswordReset");
      }
    },
  },
};
</script>
