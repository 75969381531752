<template>
  <div>
    <!-- <h1 class="subtitle title has-text-centered">
      Welcome {{ user.name }}
    </h1> -->
    <h2 class="title has-text-centered section">
      You are just one step away to start:
    </h2>
    <div class="columns is-centered">
      <div class="column is-half has-text-centered">
        <b-icon icon="check" type="is-primary" /> Tracking any users, songs, hashtags or videos
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-half has-text-centered">
        <b-icon icon="check" type="is-primary" /> Monitoring TikTok trends
      </div>
    </div>
    <p class="subtitle has-text-centered">
      Just pick the right plan for you.
    </p>
    <stripe-billing />
    <div class="section">
      <div class="box">
        <FAQ />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StripeBilling from "@/components/stripe/StripeBilling";
import FAQ from "@/components/stripe/FAQ";

export default {
  components: {
    StripeBilling,
    FAQ,
  },
  computed: {
    ...mapGetters("user", ["user"]),
  },
};
</script>

<style></style>
