<template>
  <li :class="{ 'is-primary': isDropdownActive }">
    <component
      :is="componentIs"
      :to="itemTo"
      :href="itemHref"
      exact-active-class="is-active"
      :class="{ 'has-icon': !!item.icon }"
      @click="menuClick"
    >
      <b-tooltip :label="item.label" position="is-right">
        <b-icon
          v-if="item.icon"
          :icon="item.icon"
          :class="{ 'has-update-mark': item.updateMark }"
          size="is-medium"
        />
        <!-- <span v-if="item.label" :class="{'menu-item-label':!!item.icon}"></span> -->
      </b-tooltip>
    </component>
    <aside-menu-list v-if="hasDropdown" :menu="item.menu" :is-submenu-list="true" />
  </li>
</template>

<script>
export default {
  name: "AsideMenuItem",
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isDropdownActive: false,
    };
  },
  computed: {
    componentIs() {
      return this.item.to ? "router-link" : "a";
    },
    hasDropdown() {
      return !!this.item.menu;
    },
    itemTo() {
      return this.item.to ? this.item.to : null;
    },
    itemHref() {
      return this.item.href ? this.item.href : null;
    },
  },
  methods: {
    menuClick() {
      this.$emit("menu-click", this.item);

      if (this.hasDropdown) {
        this.isDropdownActive = !this.isDropdownActive;
      }
    },
  },
};
</script>
