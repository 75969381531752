const pricingTest = {
  starter: {
    monthly: "price_1JvH0DKfuNKnnl0ToWeuqQnN",
    yearly: "price_1JvH0DKfuNKnnl0TSWTwK8rK",
  },
  growth: {
    monthly: "price_1JvH0DKfuNKnnl0TBtAqP25H",
    yearly: "price_1JvH0DKfuNKnnl0TQM6SgSJR",
  },
  pro: {
    monthly: "price_1JvH0DKfuNKnnl0Tcuax8MHZ",
    yearly: "price_1JvH0DKfuNKnnl0TpT8l5yuS",
  },
};

const pricingLive = {
  starter: {
    monthly: "price_1JvH0SKfuNKnnl0TnNw9jDkG",
    yearly: "price_1JvH0SKfuNKnnl0TkPvJpfzv",
  },
  growth: {
    monthly: "price_1JvH0SKfuNKnnl0TWu4GGl9t",
    yearly: "price_1JvH0SKfuNKnnl0TZYj7tzAM",
  },
  pro: {
    monthly: "price_1JvH0SKfuNKnnl0TiP7ez9p0",
    yearly: "price_1JvH0SKfuNKnnl0TmIPOMj8f",
  },
};

const pricing = process.env.VUE_APP_STRIPE_ENV === "live" ? pricingLive : pricingTest;

export default pricing;
