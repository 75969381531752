<template>
  <div class="column is-one-third has-text-centered">
    <div class="box">
      <div class="columns">
        <div class="column is-one-fifth">
          <b-icon :icon="icon" size="is-large" :type="iconStyle" pack="fas" />
        </div>
        <div class="column">
          <p class="is-size-5 has-text-grey">
            {{ title }}
          </p>
          <p class="is-size-3 has-text-dark">
            {{ value | formatNumber }}
          </p>

          <p v-if="growth > 0" class="is-size-5 has-text-success">
            <b-icon icon="chevron-up" size="is-small" /> {{ growth.toFixed(2) }}%
          </p>
          <p v-else-if="growth < 0" class="is-size-5  has-text-danger">
            <b-icon icon="chevron-down" size="is-small" /> {{ growth.toFixed(2) }}%
          </p>
          <p v-else class="is-size-5">{{ growth }}%</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    iconColor: {
      type: String,
      default: null,
    },
    value: {
      type: Number,
      default: null,
    },
    growth: {
      type: Number,
      default: null,
    },
  },
  computed: {
    iconStyle() {
      return "is-" + this.iconColor;
    },
  },
};
</script>
