<template>
  <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top">
    <div class="navbar-brand">
      <a class="navbar-item is-hidden-desktop" @click.prevent="menuToggleMobile">
        <b-icon :icon="menuToggleMobileIcon" />
      </a>
    </div>
    <div class="navbar-brand is-right">
      <div class="navbar-item navbar-item-menu-toggle is-hidden-desktop">
        <a @click.prevent="menuNavBarToggle">
          <b-icon :icon="menuNavBarToggleIcon" custom-size="default" />
        </a>
      </div>
    </div>
    <div class="navbar-menu fadeIn animated faster" :class="{ 'is-active': isMenuNavBarActive }">
      <div class="navbar-end">
        <b-dropdown position="is-bottom-left" append-to-body aria-role="menu" trap-focus>
          <a slot="trigger" class="navbar-item" role="button">
            <b-icon icon="bell" />
            <span v-if="unreadNotification > 0" class="badge is-left">{{
              unreadNotification
            }}</span>
          </a>

          <b-dropdown-item aria-role="menu-item" :focusable="false" custom>
            <div class="container" style="width:300px;">
              <div v-for="notif in notifications" :key="notif.id">
                <Panel :notif="notif" />
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>

        <a class="navbar-item" title="Log out" @click="logout">
          <b-icon icon="logout" custom-size="default" />
          <span>Log out</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import Panel from "@/components/notification/Panel";

export default {
  name: "NavBar",
  components: {
    Panel,
  },
  data() {
    return {
      isMenuNavBarActive: false,
    };
  },
  computed: {
    menuNavBarToggleIcon() {
      return this.isMenuNavBarActive ? "close" : "dots-vertical";
    },
    menuToggleMobileIcon() {
      return this.isAsideMobileExpanded ? "backburger" : "forwardburger";
    },
    ...mapState(["isNavBarVisible", "isAsideMobileExpanded"]),
    ...mapGetters("notifications", ["notifications", "unreadNotification"]),
  },
  mounted() {
    this.$store.dispatch("notifications/getNotifications");
  },
  methods: {
    menuToggleMobile() {
      this.$store.commit("asideMobileStateToggle");
    },
    menuNavBarToggle() {
      this.isMenuNavBarActive = !this.isMenuNavBarActive;
    },
    logout() {
      this.$store.dispatch("auth/signOut");
      this.$router.push("/signin");
    },
  },
};
</script>
