<template>
  <div class="box">
    <card-component title="Songs" icon="account">
      <div v-if="isEmpty === 0">
        <EmptySong />
      </div>
      <div v-else class="columns is-centered">
        <NumberBox
          icon="video"
          title="Total Videos"
          :growth="getGrowthPosts"
          :value="getTotalPosts"
        />
      </div>
      <div class="columns container ">
        <div class="column has-text-right">
          <router-link to="songs">
            <span class="has-text-right">
              Songs Dashboard
              <b-icon icon="chevron-right" />
            </span>
          </router-link>
        </div>
      </div>
    </card-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardComponent from "@/components/CardComponent";
import EmptySong from "@/components/home/EmptySong";

import NumberBox from "@/components/home/NumberBox";

export default {
  components: {
    CardComponent,
    EmptySong,
    NumberBox,
  },
  computed: {
    ...mapGetters("tiktok", ["tiktok"]),
    isEmpty() {
      const arr = this.tiktok.slice();
      const filtered = arr.filter((value, index, arr) => value.type === "song");
      return filtered.length;
    },
    getTotalPosts() {
      const arr = this.tiktok.slice();
      let totalPosts = 0;
      arr.forEach(element => {
        if (element.type === "song") {
          totalPosts += element.posts;
        }
      });

      return totalPosts;
    },
    getGrowthPosts() {
      const arr = this.tiktok.slice();
      let totalPosts = 0;
      let newPosts = 0;
      arr.forEach(element => {
        if (element.type === "song") {
          totalPosts += element.posts;
          if (element.new_posts !== undefined) {
            newPosts += element.new_posts;
          }
        }
      });
      return (1 - (totalPosts - newPosts) / totalPosts) * 100;
    },
  },
};
</script>

<style></style>
