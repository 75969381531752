<template>
  <section class="hero">
    <div class="hero-body">
      <div v-if="!loading" class="container">
        <div v-if="user.plan === 'free'">
          <upgrade />
        </div>
        <div v-else class="container">
          <start-tracker />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Upgrade from "@/components/home/Upgrade";
import StartTracker from "@/components/home/StartTracker";

export default {
  components: {
    Upgrade,
    StartTracker,
  },

  computed: {
    ...mapGetters("user", ["user"]),
    ...mapGetters("tiktok", ["tiktok"]),

    loading() {
      return this.user === null || this.tiktok === null;
    },
  },
  mounted() {
    this.$store.dispatch("tiktok/getTiktok");
    this.$store.dispatch("user/getUser");
  },
};
</script>

<style></style>
