<template>
  <div class="box">
    <card-component title="Hashtags" icon="pound">
      <div v-if="isEmpty === 0">
        <EmptyHashtag />
      </div>
      <div v-else class="columns is-centered">
        <NumberBox
          icon="eye"
          title="Total Views"
          icon-color="info"
          :growth="getGrowthViews"
          :value="getTotalViews"
        />
      </div>
      <div class="columns container">
        <div class="column has-text-right">
          <router-link to="hashtags">
            <span class="has-text-right">
              Hashtags Dashboard
              <b-icon icon="chevron-right" />
            </span>
          </router-link>
        </div>
      </div>
    </card-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardComponent from "@/components/CardComponent";
import EmptyHashtag from "@/components/home/EmptyHashtag";

import NumberBox from "@/components/home/NumberBox";

export default {
  components: {
    CardComponent,
    EmptyHashtag,
    NumberBox,
  },
  computed: {
    ...mapGetters("tiktok", ["tiktok"]),
    isEmpty() {
      const arr = this.tiktok.slice();
      const filtered = arr.filter((value, index, arr) => value.type === "hashtag");
      return filtered.length;
    },
    getTotalViews() {
      const arr = this.tiktok.slice();
      let totalViews = 0;
      arr.forEach(element => {
        if (element.type === "hashtag") {
          totalViews += element.views;
        }
      });

      return totalViews;
    },
    getGrowthViews() {
      const arr = this.tiktok.slice();
      let totalViews = 0;
      let newViews = 0;
      arr.forEach(element => {
        if (element.type === "hashtag") {
          totalViews += element.views;
          if (element.new_views !== undefined) {
            newViews += element.new_views;
          }
        }
      });
      return (1 - (totalViews - newViews) / totalViews) * 100;
    },
    getTotalPosts() {
      const arr = this.tiktok.slice();
      let totalPosts = 0;
      arr.forEach(element => {
        if (element.type === "hashtag") {
          totalPosts += element.posts;
        }
      });

      return totalPosts;
    },
    getGrowthPosts() {
      const arr = this.tiktok.slice();
      let totalPosts = 0;
      let newPosts = 0;
      arr.forEach(element => {
        if (element.type === "hashtag") {
          totalPosts += element.posts;
          if (element.new_posts !== undefined) {
            newPosts += element.new_posts;
          }
        }
      });
      return (1 - (totalPosts - newPosts) / totalPosts) * 100;
    },
  },
};
</script>

<style></style>
