<template>
  <div>
    <p class="has-text-centered is-size-7 has-margin-bottom-20">
      Save up to 20% with yearly billing
    </p>
    <b-tabs v-model="activeTab" type="is-toggle-rounded" position="is-centered" size="is-small">
      <b-tab-item label="Monthly">
        <div class="columns">
          <div class="column is-3 is-offset-2">
            <div class="box">
              <p class="subtitle has-text-primary">
                Starter
              </p>
              <p class="subtitle">
                up to
                <span class="title">5</span> trackers
                <br />
                <!-- <span class="is-size-7">to track anything</span> -->
              </p>

              <p class>
                <span class="has-text-weight-bold">29€</span>/Mo
                <br />
                <span class="is-size-7">Billed monthly</span>
              </p>
              <div class="has-margin-top-15">
                <b-button v-if="user.plan === 'starter'" type="is-primary" disabled>
                  Current plan
                </b-button>
                <b-button v-else type="is-primary" @click="submit(pricing.starter.monthly)">
                  Start a free 14-day trial
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="box">
            <p class="subtitle has-text-primary">
              Growth
            </p>
            <p class="subtitle">
              up to
              <span class="title">20</span> trackers
              <br />
              <!-- <span class="is-size-7">to track Users, Hashtags, Posts</span> -->
            </p>
            <p class>
              <span class="has-text-weight-bold">109€</span>/Mo
              <br />
              <span class="is-size-7">Billed monthly</span>
            </p>
            <div class="has-margin-top-15">
              <b-button v-if="user.plan === 'growth'" type="is-primary" disabled>
                Current plan
              </b-button>
              <b-button v-else type="is-primary" @click="submit(pricing.growth.monthly)">
                Start a free 14-day trial
              </b-button>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="box">
            <p class="subtitle has-text-primary">
              Pro
            </p>
            <p class="subtitle">
              up to
              <span class="title">50</span> trackers
              <br />
            </p>
            <p class>
              <span class="has-text-weight-bold">249€</span>/Mo
              <br />
              <span class="is-size-7">Billed monthly</span>
            </p>
            <div class="has-margin-top-15">
              <b-button v-if="user.plan === 'pro'" type="is-primary" disabled>
                Current plan
              </b-button>
              <b-button v-else type="is-primary" @click="submit(pricing.pro.monthly)">
                Start a free 14-day trial
              </b-button>
            </div>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="Yearly">
        <div class="columns">
          <div class="column is-3  is-offset-2">
            <div class="box">
              <p class="subtitle has-text-primary">
                Starter
              </p>
              <p class="subtitle">
                up to
                <span class="title">5</span> trackers
                <br />
                <!-- <span class="is-size-7">to track anything</span> -->
              </p>

              <p class>
                <span class="has-text-weight-bold">25€</span>/Mo
                <br />
                <span class="is-size-7">300€ billed annually</span>
              </p>
              <div class="has-margin-top-15">
                <b-button v-if="user.plan === 'starter-yearly'" type="is-primary" disabled>
                  Current plan
                </b-button>
                <b-button v-else type="is-primary" @click="submit(pricing.starter.yearly)">
                  Start a free 14-day trial
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="box">
            <p class="subtitle has-text-primary">
              Growth
            </p>
            <p class="subtitle">
              up to
              <span class="title">20</span> trackers
              <br />
              <!-- <span class="is-size-7">to track Users, Hashtags, Posts</span> -->
            </p>
            <p class>
              <span class="has-text-weight-bold">90.8€</span>/Mo
              <br />
              <span class="is-size-7">1080€ billed annually</span>
            </p>
            <div class="has-margin-top-15">
              <b-button v-if="user.plan === 'growth-yearly'" type="is-primary" disabled>
                Current plan
              </b-button>
              <b-button v-else type="is-primary" @click="submit(pricing.growth.yearly)">
                Start a free 14-day trial
              </b-button>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="box">
            <p class="subtitle has-text-primary">
              Pro
            </p>
            <p class="subtitle">
              up to
              <span class="title">50</span> trackers
              <br />
            </p>
            <p class>
              <span class="has-text-weight-bold">207.5€</span>/Mo
              <br />
              <span class="is-size-7">2490€ billed annually</span>
            </p>
            <div class="has-margin-top-15">
              <b-button v-if="user.plan === 'pro-yearly'" type="is-primary" disabled>
                Current plan
              </b-button>
              <b-button v-else type="is-primary" @click="submit(pricing.pro.yearly)">
                Start a free 14-day trial
              </b-button>
            </div>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
    <div class="columns">
      <div class="column is-offset-2">
        <b-icon icon="check" type="is-primary" /> You won't be billed today
      </div>
    </div>
    <div class="columns ">
      <div class="column is-offset-2">
        <b-icon icon="check" type="is-primary" /> Cancel any time before
        {{ time }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import pricing from "../../lib/stripe-pricing";
// eslint-disable-next-line
const stripe = Stripe(process.env.VUE_APP_stripe_key);

export default {
  components: {},
  data() {
    return {
      activeTab: 0,
      pricing,
    };
  },
  computed: {
    time() {
      const current = new Date();
      const endTrial = new Date();
      endTrial.setDate(current.getDate() + 14);
      return endTrial.toDateString();
    },
    ...mapGetters("user", ["user"]),
  },
  methods: {
    submit(id) {
      this.$amplitude.logEvent("user has chosen plan", { plan: id });
      axios
        .post(
          `${process.env.VUE_APP_STRIPE_SERVICE}/checkout?token=${process.env.VUE_APP_AUTH_TOKEN}`,
          {
            email: this.user.email,
            plan: id,
          }
        )
        .then(response => {
          const { id: sessionId } = response.data;
          stripe.redirectToCheckout({
            sessionId,
          });
        })
        .catch(e => {
          console.log(e);
          this.$buefy.toast.open("Unable to create checkout");
        });
    },
  },
};
</script>
