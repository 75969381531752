<template>
  <section class="is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="column is-4 is-offset-4">
          <h3 class="title has-text-black-bis">
            Sign in
          </h3>
          <p class="subtitle has-text-grey">
            Please sign in to proceed.
          </p>
          <div class="box">
            <!-- <figure class="avatar">
              <img src="../../assets/logo.png">
            </figure> -->
            <v-alert />
            <b-button
              class="button is-medium is-fullwidth has-margin-bottom-25 is-google"
              icon-left="google"
              @click="socialLogin"
            >
              Continue with Google
            </b-button>
            <div class="is-divider" data-content="OR" />
            <form @submit.prevent="signIn">
              <b-field>
                <b-input
                  v-model="email"
                  placeholder="Your Email"
                  autofocus
                  size="is-large"
                  type="email"
                  icon="at"
                />
              </b-field>
              <b-field>
                <b-input
                  v-model="pass"
                  placeholder="Your password"
                  autofocus
                  size="is-large"
                  type="password"
                  icon="key"
                />
              </b-field>
              <button class="button is-block is-primary is-large is-fullwidth" type="submit">
                Login
              </button>
            </form>
          </div>
          <p class="has-text-grey">
            <a href="/signup">Sign Up</a> &nbsp;·&nbsp;
            <a href="/passwordReset">Forgot Password</a> &nbsp;·&nbsp;
            <!-- <a href="../">Need Help?</a> -->
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import router from "@/router";
import store from "@/store";
import Alert from "@/components/auth/Alert.vue";
import identifyUser from "../../lib/identify-user";

Vue.component("v-alert", Alert);
export default {
  data() {
    return {
      email: "",
      pass: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },
  mounted() {
    this.$amplitude.logEvent("user start signin");

    const { mockToken } = this.$route.query;

    if (mockToken) {
      store
        .dispatch("auth/signInWithToken", {
          token: mockToken,
        })
        .then(() => {
          router.push("dashboard");
        });
    }
  },
  methods: {
    async signIn() {
      const user = await store.dispatch("auth/signIn", {
        username: this.email,
        password: this.pass,
      });

      if (!this.hasAuthenticationStatus) {
        identifyUser(user);
        this.$amplitude.logEvent("user has sign in");
        router.push("dashboard");
      }
    },
    socialLogin() {
      this.$store.dispatch("auth/signInWithGoogle").then(user => {
        identifyUser(user);
        this.$amplitude.logEvent("user has signup");
        router.push("dashboard");
      });
    },
  },
};
</script>
<style></style>
