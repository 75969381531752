import firebase from "firebase";
import axios from "axios";

const state = {
  tiktok: null,
  tiktokUser: null,
  music: null,
  post: null,
  alerts: null,
  searchAlerts: null,
  sound: {},
  loading: true,
};

const getters = {
  tiktok: state => state.tiktok,
  tiktokUser: state => state.tiktokUser,
  getMusic: state => state.music,
  getPost: state => state.post,
  getAlerts: state => state.alerts,
  getSearchAlerts: state => state.searchAlerts,
  sound: state => {
    if (state.sound === null) {
      return {};
    }

    return state.sound;
  },
  loading: state => state.loading,
};

const mutations = {
  setTiktok(state, tiktok) {
    state.tiktok = tiktok;
  },
  setTiktokUser(state, tiktokUser) {
    state.tiktokUser = tiktokUser;
  },
  clearTiktok(state) {
    state.tiktok = null;
    state.tiktokUser = null;
  },
  setMusic(state, music) {
    state.music = music;
  },
  setPost(state, post) {
    state.post = post;
  },
  setAlerts(state, alerts) {
    state.alerts = alerts;
  },
  setSearchAlerts(state, searchAlerts) {
    state.searchAlerts = searchAlerts;
  },
  setSound(state, sound) {
    state.sound = sound;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

const actions = {
  getTiktok: context => {
    const user = firebase.auth().currentUser;
    const docRef = firebase
      .firestore()
      .collection("tiktok")
      .doc(user.uid)
      .collection("tiktokUser");
    docRef
      .get()
      .then(doc => {
        const data = [];
        doc.forEach(d => {
          const temp = d.data();
          if (temp.type === "post") {
            temp.post_id = temp.id;
          }
          temp.id = d.id;
          if (temp.deleted !== true) {
            data.push(temp);
          }
        });
        context.commit("setTiktok", data);
        // set the data to tiktok
      })
      .catch(error => {
        console.log("Error getting document:", error);
      });
  },
  getTracking: (context, params) => {
    let data = {};
    const user = firebase.auth().currentUser;
    const docRef = firebase
      .firestore()
      .collection("tiktok")
      .doc(user.uid)
      .collection("tiktokUser")
      .doc(params.id);
    docRef
      .get()
      .then(doc => {
        if (doc.exists) {
          data = doc.data();
          data.id = doc.id;
          const arr = [];
          const stat = [];

          let dataRef = docRef.collection("data");
          const user = context.rootGetters["user/user"];
          if (user.plan === "free") {
            const date = new Date();
            date.setMonth(date.getMonth() - 1);
            dataRef = dataRef.where("scan_date", ">=", date);
          }
          if (user.plan === "growth") {
            const date = new Date();
            date.setMonth(date.getMonth() - 3);
            dataRef = dataRef.where("scan_date", ">=", date);
          }
          dataRef.get().then(d => {
            d.forEach(element => {
              arr.push(element.data());
            });
          });
          docRef
            .collection("stats")
            .get()
            .then(d => {
              d.forEach(element => {
                stat.push(element.data());
              });
            });
          data.data = arr;
          data.stat = stat;
          context.commit("setTiktokUser", data);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
        // set the data to tiktok
      })
      .catch(error => {
        console.log("Error getting document:", error);
      });
  },
  refreshMusic: (context, params) => {
    let data = {};
    const user = firebase.auth().currentUser;
    const docRef = firebase
      .firestore()
      .collection("tiktok")
      .doc(user.uid)
      .collection("tiktokUser")
      .doc(params.id);
    docRef.get().then(doc => {
      if (doc.exists) {
        data = doc.data();
        const arr = [];
        docRef
          .collection("topvideo")
          .get()
          .then(d => {
            d.forEach(element => {
              arr.push(element.data());
            });
          });
        data.top_videos = arr;
        context.commit("setMusic", data);
      }
    });
  },
  clearTracking: context => {
    context.commit("setTiktokUser", null);
  },
  saveTrackingUser: (context, params) => {
    const user = firebase.auth().currentUser;
    const docRef = firebase
      .firestore()
      .collection("tiktok")
      .doc(user.uid)
      .collection("tiktokUser")
      .add({
        nick_name: params.nick_name,
        engagement_rate: params.engagement_rate,
        average_comment: params.average_comment,
        average_like: params.average_like,
        avatar: params.avatar,
        unique_id: params.unique_id,
        following: params.following,
        fans: params.fans,
        heart: params.heart,
        video: params.video,
        region: params.user_region === undefined ? "" : params.user_region,
        type: "user",
        start_date: firebase.firestore.Timestamp.fromDate(new Date()),
      });
    docRef.then(doc => {
      firebase
        .firestore()
        .collection("tiktok")
        .doc(user.uid)
        .collection("tiktokUser")
        .doc(doc.id)
        .collection("data")
        .add({
          fans: params.fans,
          fans_gained: 0,
          heart: params.heart,
          heart_gained: 0,
          following: params.following,
          following_gained: 0,
          video: params.video,
          new_video: 0,
          scan_date: firebase.firestore.Timestamp.fromDate(new Date()),
        });
      context.dispatch("getTiktok");
    });
  },
  saveTrackingHashtag: (context, params) => {
    const user = firebase.auth().currentUser;
    const docRef = firebase
      .firestore()
      .collection("tiktok")
      .doc(user.uid)
      .collection("tiktokUser")
      .add({
        challenge_name: params.challenge_name,
        avatar: params.avatar === undefined ? "" : params.avatar,
        posts: 0,
        challenge_id: params.challenge_id,
        views: params.views === undefined ? 0 : params.views,
        text: params.text === undefined ? "" : params.text,
        type: "hashtag",
        start_date: firebase.firestore.Timestamp.fromDate(new Date()),
      });
    docRef.then(doc => {
      firebase
        .firestore()
        .collection("tiktok")
        .doc(user.uid)
        .collection("tiktokUser")
        .doc(doc.id)
        .collection("data")
        .add({
          posts: 0,
          new_post: 0,
          views: params.views,
          new_views: 0,
          scan_date: firebase.firestore.Timestamp.fromDate(new Date()),
        });
      context.dispatch("getTiktok");
    });
  },
  saveMusic: (context, params) => {
    const user = firebase.auth().currentUser;
    const docRef = firebase
      .firestore()
      .collection("tiktok")
      .doc(user.uid)
      .collection("tiktokUser")
      .add({
        id: params.id,
        name: params.name,
        posts: params.posts !== undefined ? params.posts : 0,
        cover: params.cover !== undefined ? params.cover : "",
        author: params.author !== undefined ? params.author : "",
        author_id: params.author_id !== undefined ? params.author_id : "",
        url: params.url !== undefined ? params.url : "",
        notification: params.notification,
        limit: params.limit,
        type: "song",
        region: params.region,
        start_date: firebase.firestore.Timestamp.fromDate(new Date()),
      });
    docRef.then(doc => {
      firebase
        .firestore()
        .collection("tiktok")
        .doc(user.uid)
        .collection("tiktokUser")
        .doc(doc.id)
        .collection("topvideo")
        .add({
          scan_date: firebase.firestore.Timestamp.fromDate(new Date()),
          posts: 0,
        });
      context.dispatch("getTiktok");
    });
  },
  updateMusicName: (context, params) => {
    const user = firebase.auth().currentUser;
    firebase
      .firestore()
      .collection("tiktok")
      .doc(user.uid)
      .collection("tiktokUser")
      .doc(params.id)
      .update({ label: params.label });
    context.dispatch("getTiktok").then(() => {
      context.dispatch("refreshMusic", { id: params.id });
    });
  },
  deleteSearch: (context, params) => {
    const user = firebase.auth().currentUser;

    firebase
      .firestore()
      .collection("tiktok")
      .doc(user.uid)
      .collection("tiktokUser")
      .doc(params.id)
      .update({
        deleted: true,
      })
      .then(() => {
        console.log("Document successfully deleted!");
        context.dispatch("getTiktok");
        // should delete all the alerts as well
        firebase
          .firestore()
          .collection("alerts")
          .where("search_id", "==", params.id)
          .get()
          .then(doc => {
            doc.forEach(d => {
              d.ref.delete().then(() => {
                context.dispatch("getAlerts");
              });
            });
          })
          .catch(error => {
            console.log("Error getting document:", error);
          });
      })
      .catch(error => {
        console.error("Error removing document: ", error);
      });
  },
  savePost: (context, params) => {
    const user = firebase.auth().currentUser;
    const docRef = firebase
      .firestore()
      .collection("tiktok")
      .doc(user.uid)
      .collection("tiktokUser")
      .add({
        id: params.id,
        user_id: params.user_id,
        user_nickname: params.user_nickname,
        unique_id: params.unique_id,
        text: params.text,
        create_time: params.create_time,
        video_cover: params.video_cover,
        video_duration: params.video_duration,
        music_author_name: params.music_author_name,
        music_name: params.music_name,
        modification_date: firebase.firestore.Timestamp.fromDate(new Date()),
        total_share: params.share_count !== undefined ? params.share_count : 0,
        total_comment: params.comment_count !== undefined ? params.comment_count : 0,
        total_digg: params.digg_count !== undefined ? params.digg_count : 0,
        total_views: params.views !== undefined ? params.views : 0,
        type: "post",
      });
    docRef.then(doc => {
      firebase
        .firestore()
        .collection("tiktok")
        .doc(user.uid)
        .collection("tiktokUser")
        .doc(doc.id)
        .collection("data")
        .add({
          scan_date: firebase.firestore.Timestamp.fromDate(new Date()),
          share: 0,
          digg: 0,
          comment: 0,
          views: 0,
        });
      context.dispatch("getTiktok");
    });
  },
  saveYoutubeChannel: (context, params) => {
    const user = firebase.auth().currentUser;
    const date = new Date();
    const docRef = firebase
      .firestore()
      .collection("tiktok")
      .doc(user.uid)
      .collection("tiktokUser")
      .add({
        channel_id: params.id,
        description: params.snippet.description,
        avatar: params.snippet.thumbnails.medium.url,
        country: params.snippet.country !== undefined ? params.snippet.country : "",
        title: params.snippet.title,
        subscriber_count: Number(params.statistics.subscriberCount),
        video_count: Number(params.statistics.videoCount),
        view_count: Number(params.statistics.viewCount),
        new_view_count: 0,
        new_video_count: 0,
        new_subscriber_count: 0,
        creation_date: firebase.firestore.Timestamp.fromDate(date),
        modification_date: firebase.firestore.Timestamp.fromDate(date),
        type: "youtube-channel",
      });
    docRef.then(doc => {
      firebase
        .firestore()
        .collection("tiktok")
        .doc(user.uid)
        .collection("tiktokUser")
        .doc(doc.id)
        .collection("data")
        .add({
          subscriber_count: Number(params.statistics.subscriberCount),
          video_count: Number(params.statistics.videoCount),
          view_count: Number(params.statistics.viewCount),
          new_view_count: 0,
          new_video_count: 0,
          new_subscriber_count: 0,
          creation_date: firebase.firestore.Timestamp.fromDate(date),
        });
      context.dispatch("getTiktok");
    });
  },
  refreshPost: (context, params) => {
    let data = {};
    const user = firebase.auth().currentUser;
    const docRef = firebase
      .firestore()
      .collection("tiktok")
      .doc(user.uid)
      .collection("tiktokUser")
      .doc(params.id);
    docRef.get().then(doc => {
      if (doc.exists) {
        data = doc.data();
        const arr = [];
        docRef
          .collection("data")
          .get()
          .then(d => {
            d.forEach(element => {
              arr.push(element.data());
            });
          });
        data.data = arr;
        context.commit("setPost", data);
      }
    });
  },
  deleteWithID: (context, params) => {
    const user = firebase.auth().currentUser;
    firebase
      .firestore()
      .collection("tiktok")
      .doc(user.uid)
      .collection("tiktokUser")
      .doc(params.id)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
        context.dispatch("getTiktok");
      })
      .catch(error => {
        console.error("Error removing document: ", error);
      });
  },
  saveAlert: (context, params) => {
    const user = firebase.auth().currentUser;
    firebase
      .firestore()
      .collection("alerts")
      .add({
        alert_for: params.for,
        alert_type: params.type,
        alert_kind: params.kind,
        value: params.value,
        user_id: user.uid,
        search_id: params.search_id,
        name: params.name,
        triggered: params.triggered,
        alert_id: params.alert_id,
        create_time: new Date(),
      })
      .then(function(docRef) {
        context.dispatch("getAlerts");
        context.dispatch("getSearchAlerts", { search_id: params.search_id });
        console.log("Document written with ID: ", docRef.id);
      })
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });
  },
  getAlerts: context => {
    const user = firebase.auth().currentUser;
    firebase
      .firestore()
      .collection("alerts")
      .where("user_id", "==", user.uid)
      .get()
      .then(doc => {
        const data = [];
        doc.forEach(d => {
          const temp = d.data();
          temp.id = d.id;
          data.push(temp);
        });
        context.commit("setAlerts", data);
      })
      .catch(error => {
        console.log("Error getting document:", error);
      });
  },
  getSearchAlerts: (context, params) => {
    const user = firebase.auth().currentUser;
    firebase
      .firestore()
      .collection("alerts")
      .where("user_id", "==", user.uid)
      .where("search_id", "==", params.search_id)
      .get()
      .then(doc => {
        const data = [];
        doc.forEach(d => {
          const temp = d.data();
          temp.id = d.id;
          data.push(temp);
        });
        context.commit("setSearchAlerts", data);
      })
      .catch(error => {
        console.log("Error getting document:", error);
      });
  },
  deleteAlert: (context, params) => {
    firebase
      .firestore()
      .collection("alerts")
      .doc(params.id)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
        context.dispatch("getAlerts");
        context.dispatch("getSearchAlerts", { search_id: params.search_id });
      })
      .catch(error => {
        console.error("Error removing document: ", error);
      });
  },
  saveMusicTag: (context, params) => {
    const user = firebase.auth().currentUser;
    const tags = firebase
      .firestore()
      .collection("tiktok")
      .doc(user.uid)
      .collection("tiktokUser")
      .doc(params.id);
    tags
      .update({
        tags: firebase.firestore.FieldValue.arrayUnion(params.tag),
      })
      .then(() => context.dispatch("getTiktok"));
  },
  deleteMusicTag: (context, params) => {
    const user = firebase.auth().currentUser;
    const tags = firebase
      .firestore()
      .collection("tiktok")
      .doc(user.uid)
      .collection("tiktokUser")
      .doc(params.id);
    tags
      .update({
        tags: firebase.firestore.FieldValue.arrayRemove(params.tag),
      })
      .then(() => context.dispatch("getTiktok"));
  },
  getSound: (context, params) => {
    context.commit("setLoading", true);
    axios
      .get(`${process.env.VUE_APP_CHART_SERVICE}/api/song/${params.id}`)
      .then(({ data }) => {
        // api.themoviedb.org manage max 1000 pages
        context.commit("setLoading", false);
        context.commit("setSound", data);
      })
      .catch(() => {
        context.commit("setLoading", false);
        context.commit("setSound", {});
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
