<template>
  <section class="is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="column is-4 is-offset-4">
          <h3 class="title has-text-grey">
            Confirm Reset Password
          </h3>
          <p class="subtitle has-text-grey">
            Confirm a password reset using the code you recieved via emailed.
          </p>
          <div class="box">
            <v-alert />

            <b-field label="Email:" label-for="usernameInput">
              <b-input
                id="usernameInput"
                v-model="username"
                type="email"
                required
                autofocus
                placeholder="Enter email"
                size="is-large"
                icon="at"
              />
            </b-field>
            <b-field label="Code:" label-for="codeInput">
              <b-input
                id="codeInput"
                v-model="code"
                required
                placeholder="Enter Code"
                size="is-large"
                icon="key"
              />
            </b-field>
            <b-field label="Password:" label-for="passwordInpute">
              <b-input
                id="passwordInput"
                v-model="password"
                required
                placeholder="Enter password"
                size="is-large"
                type="password"
                icon="key"
              />
            </b-field>
            <div class="is-2">
              <button type="submit" class="button is-primary is-large" @click="confirmSignUp">
                Submit
              </button>
              <button class="button is-primary is-large" @click="confirmResend">
                Resend Code
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import router from "@/router";
import store from "@/store";
import Alert from "@/components/auth/Alert.vue";

Vue.component("v-alert", Alert);
export default {
  data() {
    return {
      username: "",
      code: "",
      password: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["hasAuthenticationStatus"]),
  },
  methods: {
    async confirmPasswordReset() {
      await store.dispatch("auth/confirmPasswordReset", {
        username: this.username,
        code: this.code,
        password: this.password,
      });
      if (!this.hasAuthenticationStatus) {
        router.push("signIn");
      }
    },
    async passwordResetResend() {
      await store.dispatch("auth/passwordReset", {
        username: this.username,
      });
    },
  },
};
</script>
