<template>
  <div class="box">
    <card-component title="Users" icon="account">
      <div v-if="isEmpty === 0">
        <EmptyUser />
      </div>
      <div v-else class="columns">
        <NumberBox
          icon="users"
          title="Total Fans"
          icon-color="info"
          :growth="getGrowthFans"
          :value="getTotalFans"
        />
        <NumberBox
          icon="heart"
          title="Total Hearts"
          icon-color="danger"
          :growth="getGrowthLikes"
          :value="getTotalLikes"
        />
        <NumberBox
          icon="video"
          title="Total Videos"
          icon-color=""
          :growth="getGrowthVideo"
          :value="getTotalVideos"
        />
      </div>

      <div class="columns container">
        <div class="column has-text-right">
          <router-link to="users">
            <span class="has-text-right">
              Users Dashboard
              <b-icon icon="chevron-right" />
            </span>
          </router-link>
        </div>
      </div>
    </card-component>
  </div>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import EmptyUser from "@/components/home/EmptyUser";

import NumberBox from "@/components/home/NumberBox";

import { mapGetters } from "vuex";

export default {
  components: {
    CardComponent,
    NumberBox,
    EmptyUser,
  },
  computed: {
    ...mapGetters("tiktok", ["tiktok"]),
    ...mapGetters("user", ["user"]),
    getTotalLikes() {
      const arr = this.tiktok.slice();
      let totalLike = 0;
      arr.forEach(element => {
        if (element.type === "user") {
          totalLike += element.heart;
        }
      });

      return totalLike;
    },
    getGrowthLikes() {
      const arr = this.tiktok.slice();
      let totalLike = 0;
      let newLikes = 0;
      arr.forEach(element => {
        if (element.type === "user") {
          totalLike += element.heart;
          if (element.heart_gained !== undefined) {
            newLikes += element.heart_gained;
          }
        }
      });
      return (1 - (totalLike - newLikes) / totalLike) * 100;
    },
    getTotalFans() {
      const arr = this.tiktok.slice();
      let totalFans = 0;
      arr.forEach(element => {
        if (element.type === "user") {
          totalFans += element.fans;
        }
      });

      return totalFans;
    },
    getGrowthFans() {
      const arr = this.tiktok.slice();
      let totalFans = 0;
      let newFans = 0;
      arr.forEach(element => {
        if (element.type === "user") {
          totalFans += element.fans;
          if (element.fans_gained !== undefined) {
            newFans += element.fans_gained;
          }
        }
      });
      return (1 - (totalFans - newFans) / totalFans) * 100;
    },
    getTotalVideos() {
      const arr = this.tiktok.slice();
      let totalVideos = 0;
      arr.forEach(element => {
        if (element.type === "user") {
          totalVideos += element.video;
        }
      });

      return totalVideos;
    },
    getGrowthVideo() {
      const arr = this.tiktok.slice();
      let totalVideos = 0;
      let newVideo = 0;
      arr.forEach(element => {
        if (element.type === "user") {
          totalVideos += element.video;
          if (element.new_video !== undefined) {
            newVideo += element.new_video;
          }
        }
      });
      return (1 - (totalVideos - newVideo) / totalVideos) * 100;
    },
    isEmpty() {
      const arr = this.tiktok.slice();
      const filtered = arr.filter((value, index, arr) => value.type === "user");
      return filtered.length;
    },
  },
};
</script>

<style></style>
